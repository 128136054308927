<template>
     <div id="app">
          <div class="mainDiv">
               <div>
                    <h1>{{title}}</h1>
               </div>

          <div id="inputSection">
               <input id="inputTxt" v-model="stringElement" type="text" name="todoText">
               <button id="inputBtn" v-on:click="addItem" type="button">Add To Do Item</button>
          </div>

          <hr width="75%">

          <div id="workingSection">
               <div id="pendingSection" v-if="pending.length > 0">
                    <h2>Pending To Do Items (# of Items {{pending.length}})</h2>
                    <ul id="pendingList">
                         <li 
                         v-for="p in pending" 
                         :key="p.id" 
                         v-on:click="toCompleted(p)">
                              {{p.name}}
                         </li>
                    </ul>
               </div>

               <div id="completedSection" v-if="completed.length > 0">
                    <h2>Completed To Do Items (#of Items {{completed.length}})</h2>
                    <ul id="completedList"> 
                         <li 
                         v-for="c in completed" 
                         :key="c.id" 
                         v-on:click="toPending(c)">
                              {{c.name}}</li>
                    </ul>
               </div>
          </div>
     </div>
  </div>
</template>

<script>

export default {
     name: 'App',
          data() 
          {
               return {
                    stringElement: "",
                    items: 0,
                    title: "🔃 My To Do Application 🔃",
                    pending: 
                    [ 
                    ],
                    completed:
                    [
                    ]
               }
          },
          methods:{
               addItem(){
                    if(this.stringElement==""){
                         alert("Please provid ea Task to Add.");
                    }
                    else{
                         this.pending.push({id:this.items, name:this.stringElement});
                         this.items++;
                    }
                    this.stringElement = "";
               },
               toPending(listItem){
                    this.pending.push(listItem);
                    this.completed = this.completed.filter(element => element.id!=listItem.id);
               },
               toCompleted(listItem){
                    this.completed.push(listItem);
                    this.pending = this.pending.filter(element => element.id!=listItem.id);
               }
          }
}
</script>

<style>
*{
    font-family: 'Varela Round', sans-serif;
}
body{
    background-color: #4D2CDE;
}

ul {
    list-style-type: none;
    padding: 0;
    color:white;
}

li{
    cursor: pointer;
    background-color: grey;
    margin: 10px;
    padding: 20px;
    font-size: 18px;
    text-align: center;
    position: relative;
}

#completedList{
    text-decoration: line-through;   
    color: red;
}

h2, h3{
    color:#32ACFA;
}
h1 {
    font-size: 3em;
    color:#32ACFA;
}

#workingSection{
    width: 50vw;
}

.mainDiv{
    height: 100vh;
    width: 75vw;
    background:#4D2C00;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:auto;
}

#inputSection{
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#inputSection #inputTxt{
    font-size: 28px;
    padding: 10px 20px;
}

#inputSection #inputBtn{
    font-size: 24px;
    border: none;
    background-color: #32ACFA;
    color:#4D2C00;
    padding: 14px 30px;
}

#inputSection #inputBtn:hover{
    cursor: pointer;
    background: #4D2CDE;
}

#inputSection #inputBtn:active{
    background: black;
}
</style>
